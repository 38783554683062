import {Component} from "@angular/core";
import {AppStateService} from "app/services/state.service";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"]
})
export class LoginComponent {
    logoImageUrl = "assets/images/UofA-Logo.4789bf33.svg";
    backgroundImageUrl = "assets/images/background_wavy-color-lines.svg";
    successRoute = "/";

    constructor(public appStateService: AppStateService) {}
}
