import {FormFieldModel, FormService, WidgetComponent} from "@alfresco/adf-core";
import {Component, OnInit} from "@angular/core";
import {AppStateService} from "app/services/state.service";
import {FormFieldValidator} from "@alfresco/adf-core";

@Component({
    selector: "ccidlist-widget",
    templateUrl: "./ccidlist.component.html",
    styleUrls: ["./ccidlist.component.scss"]
})
export class CCIDListWidgetComponent extends WidgetComponent implements OnInit {
    ccidList = {};
    userName: string = "";
    fieldValidator = new CCIDListFieldValidator(this.userName);

    constructor(private appStateService: AppStateService, public formService: FormService) {
        super(formService);
    }

    ngOnInit(): void {
        this.field.form.fieldValidators.push(this.fieldValidator);
    }

    private setUserNameById(ccidStr: string) {
        this.appStateService.callECMApi("/people/" + ccidStr, "GET").then(
            v => {
                this.userName = v.entry?.displayName;
                this.ccidList[ccidStr] = this.userName;
                this.fieldValidator.username = this.userName;
                this.field.value=Object.keys(this.ccidList);
                this.field.validationSummary.message = "";
                this.field.validate();
                this.field.form.validateForm();
            },
            e => {
                this.userName = "";
                this.field.validationSummary.message = `Invalid CCID ${ccidStr}`;
                this.field.form.markAsInvalid();
                this.fieldValidator.username = "";
            }
        );
    }

    addCCID(ccidval: string) {
        if (ccidval !== "") {
            this.setUserNameById(ccidval);
        }
        this.userName="";
    }
    removeCCID() {
        this.field.value = "";
        this.ccidList = {};
        this.field.form.validateForm();
    }

}

class CCIDListFieldValidator implements FormFieldValidator {
    constructor(public username: string) {}

    isSupported(field: FormFieldModel): boolean {
        return field && field.type === "ccidlist";
    }

    validate(field: FormFieldModel): boolean {
        if (this.isSupported(field)) {
            if (this.username === "") {
                return false;
            }

            if (field?.value === null) {
                return false;
            }
        }
        return true;
    }
}
