<div class="uofa-task-form">
    <div *ngIf="isTaskDetailLoading; else showTask" class="task-spinner-continer"></div>
    <ng-template #showTask>
        <div *ngIf="!taskDetails; else taskDetailView">
            <h2 class="tasks-details-empty">Can not find task {{ taskId }}</h2>
        </div>
        <ng-template #taskDetailView>
            <div>
                <h2 *ngIf="!isTaskDetailLoading" class="task-details-header">
                    {{ taskDetails?.name || "No name" }}
                </h2>

                <mat-list class="task-details-header-list">
                    <mat-grid-list *ngIf="taskDetails?.dueDate; else showPlaceholder" cols="3" rowHeight="64px">
                        <mat-grid-tile class="task-details-header-info" [colspan]="1">Assignee: {{ getAssignee() }}</mat-grid-tile>
                        <mat-grid-tile class="task-details-header-info" [colspan]="1">Due: {{ getDate(taskDetails?.dueDate) }}</mat-grid-tile>
                        <mat-grid-tile class="task-details-header-info" [colspan]="1">Created: {{ getDate(taskDetails?.created) }}</mat-grid-tile>
                    </mat-grid-list>
                    <ng-template #showPlaceholder>
                        <mat-grid-list cols="2" rowHeight="64px">
                            <mat-grid-tile class="task-details-header-info" [colspan]="1">Assignee: {{ getAssignee() }}</mat-grid-tile>
                            <mat-grid-tile class="task-details-header-info" [colspan]="1">Created: {{ getDate(taskDetails?.created) }}</mat-grid-tile>
                        </mat-grid-list>
                    </ng-template>
                </mat-list>
                <uofa-adf-task-form
                    [taskId]="taskDetails.id"
                    [taskDetails]="taskDetails"
                    [showCancelButton]="false"
                    [showFormRefreshButton]="false"
                    [fieldValidators]="fieldValidators"
                    (formSaved)="onFormSaved($event)"
                    (formLoaded)="onFormLoaded($event)"
                    (taskUnclaimed)="onTaskUnclaimed()"
                    (taskClaimed)="onTaskClaimed()"
                    (formCompleted)="onFormCompleted($event)"
                    (error)="onFormError($event)"
                    #uofaTaskForm
                ></uofa-adf-task-form>
            </div>

            <mat-card class="uofa-process-comments" *ngIf="this.enableProcessComments">
                <mat-card-content>
                    <uofa-adf-process-comments [processInstanceId]="taskDetails.processInstanceId" [readOnly]="taskDetails.isCompleted()"> </uofa-adf-process-comments>
                </mat-card-content>
            </mat-card>
        </ng-template>

        <ng-template #errorDialog>
            <h3 matDialogTitle>{{ "ADF_TASK_LIST.DETAILS.ERROR.TITLE" | translate }}</h3>
            <mat-dialog-content>
                <p>{{ "ADF_TASK_LIST.DETAILS.ERROR.DESCRIPTION" | translate }}</p>
            </mat-dialog-content>
            <mat-dialog-actions>
                <button mat-button type="button" (click)="closeErrorDialog()">{{ "ADF_TASK_LIST.DETAILS.ERROR.CLOSE" | translate }}</button>
            </mat-dialog-actions>
        </ng-template>
    </ng-template>
    <mat-stepper style="display: none;">
        <mat-step></mat-step>
    </mat-stepper>
    <!-- <adf-task-header
        [taskDetails]="taskDetails"
    >
    </adf-task-header> -->
</div>
