import {AlfrescoApiService, AppConfigService, AuthenticationService} from "@alfresco/adf-core";
import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {AppStateService} from "app/services/state.service";

@Component({
    selector: "app-root",
    templateUrl: "./app-layout.component.html",
    styleUrls: ["./app-layout.component.scss"]
})
export class AppLayoutComponent implements OnInit {
    logo = "assets/images/Primary_Logo_Complete_Package/White/Digital/UA_Logo_WHT_RGB.png";
    backgroundColor = "#275d38";
    title = "";
    taskView: boolean = false;

    constructor(public appStateService: AppStateService, public route: ActivatedRoute, private appConfigService: AppConfigService, private router: Router, private alfrescoApiService: AlfrescoApiService, private authService: AuthenticationService) {
        let url = this.router.url;
        const path = url.split("/");
        const applicationId = Number(path[2]);
        const taskId = Number(path[4]);
        if (url && applicationId && applicationId !== 0) {
            if (applicationId && applicationId !== 0) {
                this.title = this.appConfigService.config.apps[applicationId]?.name;
            } else {
                this.title = "";
            }
        } else {
            this.title = "";
        }

        if (taskId) {
            this.taskView = true;
        }
    }

    ngOnInit() {
        // TODO check user permission
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const applicationId = Number(this.route.snapshot.firstChild.params?.appId);
                if (applicationId && applicationId !== 0) {
                    this.title = this.appConfigService.config.apps[applicationId]?.name;
                } else {
                    this.title = "";
                }

                const taskId = Number(this.route.snapshot.firstChild.params?.taskId);
                if (taskId) {
                    this.taskView = true;
                } else {
                    this.taskView = false;
                }
            }
        });

        this.alfrescoApiService.getInstance().on('error', (error) => {
            if (error.status === 401) {
                console.log(error.status)
                if (!this.authService.isLoggedIn()) {
                    window.location.reload();
                }
            }

        });
    }

    onUserLogout() {
        this.appStateService.logout();
    }

    getFootYear() {
        return new Date().getFullYear().toString();
    }
}
