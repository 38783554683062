<div>
    <mat-label>CCID</mat-label>
    <mat-form-field appearence="outline" floatLabel="always">
        <input
            #ccidfield
            matInput
            type="text"
            (keyup.enter)="addCCID(ccidfield.value)"
            (blur)="addCCID(ccidfield.value); ccidfield.value=''"
        />
    </mat-form-field>
    <button (click)="removeCCID()">Remove</button>
    <ul>
        <li *ngFor="let item of ccidList | keyvalue">{{ item.value }} ({{ item.key }})</li>
    </ul>
</div>
<div class="{{ field.className }}" id="ccidlist-widget" [class.adf-invalid]="!field.isValid && isTouched()" [class.adf-left-label-input-container]="field.leftLabels">
    <error-widget [error]="field.validationSummary"></error-widget>
    <error-widget *ngIf="isInvalidFieldRequired() && isTouched()" required="{{ 'FORM.FIELD.REQUIRED' | translate }}"></error-widget>
</div>
