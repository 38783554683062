<div class="layout">
    <adf-layout-header class="layout-header" id="adf-header" [logo]="logo" [color]="backgroundColor" [showSidenavToggle]="false" [title]="title">
        <div class="adf-toolbar--spacer"></div>
        <adf-userinfo [menuPositionX]="'before'" [menuPositionY]="'above'"></adf-userinfo>
        <button mat-icon-button (click)="onUserLogout()">
            <mat-icon>logout</mat-icon>
        </button>
    </adf-layout-header>
    <div class="content" [ngClass]="{'content-task': taskView === true}">
        <router-outlet></router-outlet>
    </div>
    <footer data-gtm-vis-recent-on-screen-149576_239="119" data-gtm-vis-first-on-screen-149576_239="119" data-gtm-vis-total-visible-time-149576_239="100" data-gtm-vis-has-fired-149576_239="1">
        <div class="ack">
            <div class="main">
                <span class="lead">Territorial Acknowledgement</span>
                <p>
                    The University of Alberta, its buildings, labs, and research stations are primarily located on the traditional territory of Cree, Blackfoot, Métis, Nakota Sioux, Iroquois, Dene, and Ojibway/Saulteaux/Anishinaabe nations;
                    lands that are now known as part of Treaties 6, 7, and 8 and homeland of the Métis. The University of Alberta respects the sovereignty, lands, histories, languages, knowledge systems, and cultures of First Nations, Métis
                    and Inuit nations.
                </p>
            </div>
        </div>
        <div class="final">
            <div class="social-links">
                <a class="fab fa-facebook" href="https://www.facebook.com/ualberta" title="UAlberta Facebook" aria-hidden="true"></a><span class="sr-only"></span>
                <a class="fab fa-twitter" href="https://twitter.com/UAlberta" title="UAlberta Twitter" aria-hidden="true"></a><span class="sr-only"></span>
                <a class="fab fa-instagram" href="https://www.instagram.com/ualberta/" title="UAlberta Instagram" aria-hidden="true"></a><span class="sr-only"></span>
                <a class="fab fa-youtube" href="https://www.youtube.com/user/UniversityofAlberta" title="UAlberta YouTube" aria-hidden="true"></a><span class="sr-only"></span>
            </div>
            <div class="logo">
                <a aria-label="University of Alberta" href="https://www.ualberta.ca/index.html"></a>
            </div>
            <div class="copy">© <span id="copyright">{{getFootYear()}}</span> University of Alberta</div>
        </div>
    </footer>
    <!-- <footer class="dv-footer">
        <div class="container">
            <a href="https://www.ualberta.ca/" class="dv-ua-logo dv-footer-ua-logo"></a>
            <div class="dv-footer-copyright">© <span id="copyright">{{getFootYear()}}</span> University of Alberta</div>
        </div>
    </footer> -->
</div>
