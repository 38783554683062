import {AppConfigService, FORM_FIELD_VALIDATORS, FormModel, FormRenderingService} from "@alfresco/adf-core";
import {Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TaskDetailsModel, TaskListService, ProcessService} from "@alfresco/adf-process-services";
import {RestVariable, UserRepresentation} from "@alfresco/js-api";
import moment from "moment";

import {UofAFormComponent} from "./form/form.component";
import {CCIDWidgetComponent} from "app/widgets/ccid/ccidLookUp.component";
import {DisplayHTMLWidgetComponent} from "app/widgets/display-html/display-html.component";
import {SiteListWidgetComponent} from "app/widgets/sitelist/sitelist.component";
import {UofaDocumentWidgetComponent} from "app/widgets/document/uofa-document.component";
import {CCIDListWidgetComponent} from "app/widgets/ccidlist/ccidList.component";
import { DisplayRichTextWidgetComponent, DisplayRichTextValidator } from "app/widgets/display-rich-text/display-rich-text.widget";

@Component({
    selector: "uofa-task-form",
    templateUrl: "./task-form.component.html",
    styleUrls: ["./task-form.component.scss"]
})
export class UofATaskFormComponent implements OnInit {
    taskId: string = "";
    appId: string = "";
    isTaskDetailLoading: boolean = true;
    taskDetails: TaskDetailsModel;
    currentLoggedUser: UserRepresentation;
    errorDialogRef: MatDialogRef<TemplateRef<any>>;

    @ViewChild("uofaTaskForm")
    taskFormComponent: UofAFormComponent;

    @ViewChild("errorDialog")
    errorDialog: TemplateRef<any>;

    fieldValidators = [
        ...FORM_FIELD_VALIDATORS,
        new DisplayRichTextValidator()
    ];

    private enableProcessComments: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private taskListService: TaskListService,
        private processService: ProcessService,
        private dialog: MatDialog,
        private formRenderingService: FormRenderingService,
        private appConfigService: AppConfigService
    ) {
        this.formRenderingService.setComponentTypeResolver("ccid_look_up", () => CCIDWidgetComponent, true);
        this.formRenderingService.setComponentTypeResolver("display_html", () => DisplayHTMLWidgetComponent, true);
        this.formRenderingService.setComponentTypeResolver("document_viewer", () => UofaDocumentWidgetComponent, true);
        this.formRenderingService.setComponentTypeResolver("sitelist", () => SiteListWidgetComponent, true);
        this.formRenderingService.setComponentTypeResolver("textarea", () => DisplayRichTextWidgetComponent, true);
        this.formRenderingService.setComponentTypeResolver("ccidlist", () => CCIDListWidgetComponent, true);
    }

    ngOnInit(): void {
        this.route.params.subscribe(
            params => {
                this.taskId = params["taskId"];
                this.appId = params["appId"];
                this.loadTaskDetails(this.taskId);

                if (this.appId) {
                    this.enableProcessComments = this.appConfigService.config.apps[this.appId]?.enableProcessComments;
                }
            },
            error => {
                console.log(error);
            }
        );
    }

    onTaskClaimed() {
        // this.taskFormComponent?.reloadTask();
        this.loadTaskDetails(this.taskId);
    }

    onTaskUnclaimed() {
        this.loadTaskDetails(this.taskId);
    }

    onFormLoaded(form: FormModel): void {
        this.isTaskDetailLoading = false;
    }

    /** Emitted when the form is submitted with the `Save` or custom outcomes. */
    onFormSaved(event: {form: FormModel; type: string}): void {
        // console.log(event)
        if (event.type === "SAVE") {
            this.taskFormComponent.loading = true;
            let variables: RestVariable[] = [];
            Object.keys(event.form?.values).forEach(key => {
                const currentVariableValue = event.form.values[key]?.name;
                variables.push({
                    name: key,
                    scope: "global",
                    type: "string",
                    value: currentVariableValue
                });
            });
            if (this.taskDetails?.processInstanceId) {
                this.processService.createOrUpdateProcessInstanceVariables(this.taskDetails.processInstanceId, variables).subscribe(
                    result => {
                        this.taskFormComponent.loading = false;
                    },
                    error => {
                        console.log(error);
                        this.taskFormComponent.loading = false;
                    }
                );
            }
        }
    }

    isLoading(): boolean {
        return this.isTaskDetailLoading || this.taskFormComponent?.loading;
    }

    getAssignee(): string {
        return this.taskDetails?.assignee?.id ? `${this.taskDetails.assignee.firstName} ${this.taskDetails.assignee.lastName}` : `Null`;
    }

    getDate(date): string {
        return date ? moment(date).format("ll") : `Null`;
    }

    /** Emitted when the form is submitted with the `Complete` outcome. or custom outcomes!!!! this should be a bug in the source code, please double check when upgrade package */
    onFormCompleted(event: {form: FormModel; type: string}): void {
        this.taskFormComponent?.reloadTask();
    }

    onFormError(error: any) {
        this.dialog.open(this.errorDialog);
    }

    closeErrorDialog() {
        this.dialog.closeAll();
    }

    private loadTaskDetails(taskId: string) {
        this.isTaskDetailLoading = true;
        if (taskId) {
            this.taskListService.getTaskDetails(taskId).subscribe(
                (res: TaskDetailsModel) => {
                    this.taskDetails = res;

                    if (!this.taskDetails.name) {
                        this.taskDetails.name = "No name";
                    }
                    this.isTaskDetailLoading = false;
                },
                error => {
                    this.isTaskDetailLoading = false;
                }
            );
        }
    }
}
