<div class="adf-comments-container">
    <div id="comment-header" class="adf-comments-header">
        {{ "ADF_PROCESS_LIST.DETAILS.COMMENTS.HEADER" | translate: {count: comments?.length} }} - Visible to all parties
    </div>
    <div class="adf-comments-input-container" *ngIf="!isReadOnly()">
        <mat-form-field class="adf-full-width">
            <input matInput id="comment-input" placeholder="{{ 'ADF_PROCESS_LIST.DETAILS.COMMENTS.ADD' | translate }}" [(ngModel)]="message" (keyup.esc)="clear()" type="add" />
        </mat-form-field>
        <button mat-raised-button color="accent" aria-label="add" (click)="this.add()">Add Comment</button>
    </div>

    <div *ngIf="comments.length > 0">
        <adf-comment-list [comments]="comments"> </adf-comment-list>
    </div>
</div>
