import {FormService, WidgetComponent} from "@alfresco/adf-core";
import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";

@Component({
    selector: "display-html-widget",
    template: "<div #htmlPlaceholder></div>",
    encapsulation: ViewEncapsulation.None
})
export class DisplayHTMLWidgetComponent extends WidgetComponent implements OnInit {
    htmlValue: string = "";
    @ViewChild("htmlPlaceholder") el: ElementRef;

    constructor(public formService: FormService) {
        super(formService);
    }

    ngOnInit(): void {
        const customProperties = this.field.params.customProperties;
        let htmlValue = customProperties["uofa-adf-display-html"];
        const variable = customProperties["htmlVariable"]; 
        if (variable && variable.value) {
            const type = variable.fieldType;
            const key = `{{${variable[type].id}}}`;
            if (variable.value) {
                this.htmlValue = htmlValue.replace(key, variable.value);
            }
        } else {
            if (variable) {
                const type = variable.fieldType;
                const key = `{{${variable[type].id}}}`;
                this.htmlValue = htmlValue.replace(key, "");
            } else {
                this.htmlValue = htmlValue;
            }
        }
    }

    ngAfterViewInit() {
        this.el.nativeElement.innerHTML = this.htmlValue;
    }
}
